"use strict";

(function () {
  const scriptInit = () => {
    window.addEventListener("message", (message) => {
      const { event, status, error } = message.data;
      if (event === "amz-form") {
        sendCustomGaEvent(status, error);
      }
    });
  }

  const detectSoftware = () => {
    const host = window.location.host;
    let software = "sample_";
    switch (host) {
      case "sellerhook.com":
      case "lp.sellerhook.com":
        software += "report";
        break;
      case "enterprise.sellerhook.com":
        software += "enterprise";
        break;
      case "analysis.sellerhook.com":
        software += "analysis";
        break;
      default:
       const subdomain = host.split(".")[0]
        software += subdomain
        // console.warn("Host is undefined");
    }
    console.log(software)
    return software;
  }

  const sendCustomGaEvent = (status, err) => {
    let action = status ? 'Success' : 'Error';
    if (err) {
      action += `|${err}`;
    }
    if (window.gtag) {
      gtag('event', `${action}|${detectSoftware()}`, {
        'event_category': `${detectSoftware()}|EmailForm`,
        'event_label': window.location.href,
        'value': '',
        'transport_type': 'beacon'
      });
    } else {
      console.error("gtag is undefined!");
    }
  }

  document.addEventListener("DOMContentLoaded", function() {
    scriptInit();
  });
}());
